<template>
	<div
		v-for="(option, i) in options"
		:key="i"
		class="mb-2 flex items-center gap-1 text-sm md:text-base"
	>
		<input
			:id="generateId(option)"
			type="checkbox"
			:checked="selection.includes(option)"
			:name="name"
			:aria-label="generateAriaLabel(option)"
			:value="option"
			class="peer relative flex size-5 cursor-pointer appearance-none rounded border border-text accent-cta before:content-none checked:border-cta checked:bg-cta"
			@input="handleCheckboxChange"
		/>
		<CheckIcon
			class="pointer-events-none absolute left-0 top-0 hidden size-5 text-white peer-checked:block"
		/>
		<label :for="generateId(option)">
			{{ option }}
			<span
				v-if="optionsCount && option in optionsCount"
				class="text-sm font-light text-zinc-600"
				>({{ optionsCount[option] }})</span
			>
		</label>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import CheckIcon from '@heroicons/vue/16/solid/CheckIcon';
import type { FilterOptionsCount } from '~/types/app.types';

interface Props {
	options: Set<string>;
	optionsCount?: FilterOptionsCount | null;
	name?: string;
	selection?: string[];
	aria: string; // Short description of purpose e.g. 'Category'
}

const props = withDefaults(defineProps<Props>(), {
	optionsCount: null,
	name: '',
	selection: () => ['All'],
});

const selection = ref(props.selection);

watch(
	() => props.selection,
	(newVal) => {
		selection.value = newVal;
	},
);

const emit = defineEmits<{
	(event: 'changeOption', value: string[]): void;
}>();

function generateId(optionName: string) {
	const option = optionName.toLowerCase().replaceAll(' ', '_');
	return option === 'all'
		? `all_${props.name}_options`
		: `${option}_${props.name.replaceAll(' ', '-')}`;
}

function generateAriaLabel(optionName: string) {
	return props.optionsCount && optionName in props.optionsCount
		? `${props.aria}: ${optionName} (${props.optionsCount[optionName]})`
		: `${props.aria}: ${optionName}`;
}

// Add logic to reset to 'All' when unselected single option that isn't 'All'
function handleCheckboxChange(event: Event) {
	const target = event.target as HTMLInputElement;
	const value = target.value;

	if (target.checked) {
		selection.value.push(value);

		if (value !== 'All' && selection.value.includes('All')) {
			selection.value.splice(selection.value.indexOf('All'), 1);
		}

		if (value === 'All') {
			selection.value = ['All'];
		}
	} else {
		const index = selection.value.indexOf(value);
		if (index !== -1) {
			selection.value.splice(index, 1);
			if (!selection.value.length) {
				selection.value.push('All');
			}
		}
	}

	emit('changeOption', selection.value);
}
</script>
