<template>
	<div
		v-for="(option, i) in options"
		:key="i"
		class="mb-2 flex items-center gap-1 text-sm md:text-base"
	>
		<input
			:id="generateId(option)"
			type="checkbox"
			:aria-label="generateAriaLabel(option)"
			:checked="selection === option"
			:name="name"
			:value="option"
			class="peer relative flex size-5 cursor-pointer appearance-none rounded border border-text accent-cta before:content-none checked:border-cta checked:bg-cta"
			@click="clickHandler"
		/>
		<CheckIcon
			class="pointer-events-none absolute left-0 top-0 hidden size-5 text-white peer-checked:block"
		/>
		<label :for="generateId(option)">
			{{ option }}
			<span
				v-if="optionsCount && option in optionsCount"
				class="text-sm font-light text-zinc-600"
				>({{ optionsCount[option] }})</span
			>
		</label>
	</div>
</template>

<script setup lang="ts">
import CheckIcon from '@heroicons/vue/16/solid/CheckIcon';
import type { FilterOptionsCount } from '~/types/app.types';

interface Props {
	options: Set<string>;
	optionsCount?: FilterOptionsCount | null;
	name?: string;
	selection?: string;
	aria: string; // Short description of purpose e.g. 'Category'
}

const props = withDefaults(defineProps<Props>(), {
	optionsCount: null,
	name: '',
	selection: 'All',
});

const emit = defineEmits<{
	(event: 'changeOption', value: string): void;
}>();

function generateId(optionName: string) {
	const option = optionName.toLowerCase().replaceAll(' ', '_');
	return option === 'all'
		? `all_${props.name}_options`
		: `${option}_${props.name.replaceAll(' ', '-')}`;
}

function generateAriaLabel(optionName: string) {
	return props.optionsCount && optionName in props.optionsCount
		? `${props.aria}: ${optionName} (${props.optionsCount[optionName]})`
		: `${props.aria}: ${optionName}`;
}

function clickHandler(event: Event) {
	const target = event.target as HTMLInputElement;
	emit('changeOption', target.value === props.selection ? 'All' : target.value);
}
</script>
